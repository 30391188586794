import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { RemixBrowser } from '@remix-run/react'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import LocizeBackend, {
  type LocizeBackendOptions
} from 'i18next-locize-backend'
import { API_MODE, SENTRY_DSN } from '~/constants/env'
import { i18nextConfig } from '~/i18n/config'
import { getRouteNamespacesFromRoutes } from '~/utils/i18n-ns'
import * as Sentry from '@sentry/remix'
import { matchIsNodeProduction } from './utils/env'

if (matchIsNodeProduction()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: API_MODE
  })
}

async function hydrate() {
  if (!i18next.isInitialized) {
    await i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(LocizeBackend)
      .init<LocizeBackendOptions>({
        ...i18nextConfig,
        ns: getRouteNamespacesFromRoutes(window.__remixRouteModules),
        detection: {
          // By using the `<html lang>` attribute
          // we can communicate to the client the language detected server-side
          order: ['htmlTag'],
          // Because we only use htmlTag, there's no reason to cache the language
          // on the browser, so we disable it
          caches: []
        }
      })
  }

  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <RemixBrowser />
      </I18nextProvider>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
