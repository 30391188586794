import * as R from 'remeda'

type Routes = typeof window.__remixRouteModules

export function getRouteNamespacesFromRoutes(routes: Routes): string[] {
  return R.pipe(
    routes,
    R.values(),
    R.flatMap((route) => {
      if (
        route &&
        R.isObjectType(route) &&
        R.isObjectType(route.handle) &&
        'i18n' in route.handle
      ) {
        if (R.isString(route.handle.i18n)) {
          return [route.handle.i18n]
        }

        if (
          R.isArray(route.handle.i18n) &&
          route.handle.i18n.every(R.isString)
        ) {
          return [...route.handle.i18n]
        }
      }

      return []
    }),
    // remove duplicates
    (namespaces) => {
      return [...new Set(namespaces)]
    }
  )
}
